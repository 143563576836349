import React, { useEffect, useState } from "react";
import axios from "axios";
import { shallow } from "zustand/shallow";
import { Divider, Flex, Box, Button } from "@mantine/core";

import VehicleManagement from "./VehicleManagement";
import VehicleSetup from "./VehicleSetup";

import useAppStore from "./ride-challenge-store";

import ViewTitle from "./ViewTitle";

export default function DashboardVehicles({
  fetchData,
  setUnverifiedView,
  setDashboardView,
  children,
  allowMileageEntry,
}) {
  const {
    coords,
    dashboardData,
    effortId,
    entityInfo,
    location,
    registrationInfo,
    setAppLoading,
    user,
    verificationCode,
  } = useAppStore(
    (state) => ({
      coords: state.coords,
      dashboardData: state.dashboardData,
      effortId: state.effortId,
      entityInfo: state.entityInfo,
      location: state.location,
      registrationInfo: state.registrationInfo,
      setAppLoading: state.setAppLoading,
      user: state.user,
      verificationCode: state.verificationCode,
    }),
    shallow
  );
  const [addingVehicle, setAddingVehicle] = useState(false);
  const [vehicles, setVehicles] = useState([]);

  const foundEffort = user.campaign_efforts.find((f) => f.id === effortId);

  useEffect(() => {
    fetchVehicles();
  }, []);

  function fetchVehicles() {
    const req = {
      ...coords,
      campaign_effort_id: effortId,
      location_id: entityInfo.location?.id,
      user_id: user.id,
    };

    setAppLoading(true);

    axios
      .post(`/ride-challenge/retrieve-vehicles-for-user/`, req)
      .then(({ data }) => {
        const sortedVehicles = data.response.sort(
          (a, b) => b.odometer_total - a.odometer_total
        );

        setVehicles(sortedVehicles);
        setAppLoading(false);

        if (sortedVehicles.length === 0) {
          setAddingVehicle(true);
        }
      })
      .catch((err) => {
        setVehicles([]);
        setAppLoading(false);
      });
  }

  const codeVerificationData = {
    ...coords,
    user_location_id: user?.user_location_id,
    campaign_effort_id: effortId,
    location_id: location?.id,
    user_id: user?.id,
  };

  const userVins = vehicles
    .filter((f) => f.vin)
    .map((v) => v.vin.toLowerCase());

  return (
    <div>
      <ViewTitle title={addingVehicle ? `Add Motorcycle` : `My Motorcycles`} />
      {addingVehicle ? (
        <Box mb="lg">
          <VehicleSetup
            codeVerificationData={codeVerificationData}
            fetchData={fetchData}
            onSuccess={() => {
              fetchVehicles();
              // fetchData();
              // setView(views.dashboard);
              setAddingVehicle(false);
            }}
            additionalReqData={{
              ...coords,
              campaign_effort_id: effortId,
              location_id: entityInfo.location?.id,
              user_interaction_id:
                registrationInfo?.initial_user_interaction_id,
              user_id: user?.id,
              user_location_id: user?.user_location_id,
            }}
            userEffortData={foundEffort}
            dashboardData={dashboardData}
            verificationCode={verificationCode}
          />
        </Box>
      ) : (
        <VehicleManagement
          fetchData={() => {
            fetchVehicles();
            fetchData();
          }}
          verificationCode={verificationCode}
          vehicles={vehicles}
          codeVerificationData={codeVerificationData}
          setUnverifiedView={setUnverifiedView}
          setDashboardView={setDashboardView}
          allowMileageEntry={allowMileageEntry}
          additionalReqData={{
            ...coords,
            campaign_effort_id: effortId,
            location_id: entityInfo.location?.id,
            user_id: user.id,
          }}
        />
      )}
      {addingVehicle ? (
        <>
          <Divider mt="xl" mb="xl" opacity={0.08} />
          <Flex justify="center" gap="xs">
            <Button
              onClick={() => setAddingVehicle(!addingVehicle)}
              c="dark"
              color="gray.1"
              size="xs"
              radius="lg"
            >
              my motorcycles
            </Button>
            <>{children}</>
          </Flex>
        </>
      ) : (
        <>
          <Flex gap="xs" justify="center" mt="xl" mb="xl">
            {allowMileageEntry && (
              <Button
                radius="lg"
                onClick={() => setAddingVehicle(!addingVehicle)}
                size="xs"
              >
                Add Motorcycle
              </Button>
            )}
            <>{children}</>
          </Flex>
        </>
      )}
    </div>
  );
}
